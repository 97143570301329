<template>
  <div class="flex flex-col text-left">
    <div class="w-full flex space-x-2">
      <div class="w-full flex flex-col">
        <label for="">Bewertung</label>
        <input
          type="text"
          v-model="ratingscale.name"
          class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          :class="{ 'border-red-500': $v.ratingscale.name.$error }"
          placeholder="Name"
        />
      </div>
    </div>

    <div class="w-full flex space-x-2">
      <div class="w-full flex flex-col">
        <label for="">Beschreibung</label>
        <textarea
          v-model="ratingscale.description"
          class="mb-2 border rounded px-2 py-1 text-xs focus:outline-none"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { required, maxLength } from 'vuelidate/lib/validators'

const tempRatingScale = {
  name: '',
  description: '',
}

export default {
  name: 'rating-scale',
  props: ['payload'],
  components: {},
  data() {
    return {
      ratingscale: { ...tempRatingScale },
    }
  },
  validations() {
    return {
      ratingscale: {
        name: {
          required,
          maxLength: maxLength(1),
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.ratingscale = this.payload
        ? { ...this.payload }
        : { ...this.tempRatingScale }
    },
    save() {
      this.$v.ratingscale.$touch()
      if (this.$v.$invalid) {
        this.$toast.warning('Name darf nicht 1 Buchstabe überschreiten')
      } else {
        if (this.ratingscale.id) {
          axios
            .patch(
              `/performance-evaluation/setting/ratingscale/${this.ratingscale.id}/`,
              this.ratingscale,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(response => {
              if (response.status === 200) {
                this.$toast.success(`${response.data.message}`)
                this.$emit('close-modal', { show: false })
                this.$bus.$emit('reload-rating-data-table')
              }
            })
            .catch(err => {
              err
            })
        } else {
          axios
            .post(
              `/performance-evaluation/setting/ratingscale/`,
              this.ratingscale,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(response => {
              console.log(response)
              if (response.status === 201) {
                this.$toast.success(`${response.data.name} Speichern`)
                this.$emit('close-modal', { show: false })
                this.$bus.$emit('reload-rating-data-table')
              }
            })
            .catch(err => {
              err
            })
        }
      }
    },
  },
  watch: {},
}
</script>
